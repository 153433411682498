import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import PortalProvider from "./PortalProvider";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const portal = useSelector((state) => state.app.portal);

  useEffect(() => {
    if (!auth.user) {
      navigate("/auth/login");
    }
  }, [auth.user, children, portal, navigate]);

  return <PortalProvider portal={portal}>{children}</PortalProvider>;
};

export default ProtectedRoute;
