const INIT_STATE = {
  portals: [],
  portal: null,
};

const AppReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CACHE_PORTALS":
      return {
        ...state,
        portals: action.payload,
      };
    case "SELECT_PORTAL":
      return {
        ...state,
        portal: action.payload,
      };
    default:
      return state;
  }
};

export default AppReducer;
