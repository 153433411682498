import { combineReducers } from "redux";
import CustomizerReducer from "./customizer/CustomizerReducer";
import AppReducer from "./reducer";
import GameReducer from "./reducers/quizEditor";

const RootReducers = combineReducers({
  CustomizerReducer,
  app: AppReducer,
  quiz: GameReducer,
});

export default RootReducers;
