import React from "react";
import { Typography, Box, Grid, CircularProgress } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import Logo from "../../assets/images/logos/logo.png";

const LoadingScreen = ({ message, view }) => {
  return (
    <PageContainer title={`Jogo | ${view}`} description={view}>
      <Grid container spacing={0} justifyContent="center" alignItems="center" direction="column">
        <Grid item lg={3}>
          <Box sx={{ p: 5 }}>
            <img src={Logo} alt="jogo" style={{ height: 200 }} />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          lg={6}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Box sx={{ p: 5, textAlign: "center" }}>
            <CircularProgress />
          </Box>
          <Box sx={{ p: 5, textAlign: "center" }}>
            <Typography variant="h4">{message}</Typography>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LoadingScreen;
