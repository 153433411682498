import React, { Suspense } from "react";
import videojs from "video.js";
import "./index.css";
import "videojs-ima";
import "video.js/dist/video-js.css";
import * as contribAds from "videojs-contrib-ads";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { configureStore } from "./redux/Store";
import { CircularProgress } from "@mui/material";

if (!videojs.getPlugin("ads"))
  videojs.registerPlugin("ads", contribAds.default);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<CircularProgress />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);
