export const CachePortals = (payload) => ({
  type: "CACHE_PORTALS",
  payload,
});

export const SelectPortal = (payload) => ({
  type: "SELECT_PORTAL",
  payload,
});

export const setQuiz = (quizData) => {
  return { type: "SELECT_QUIZ", payload: quizData };
};
