import React, { Suspense } from "react";

// project imports
import Spinner from "../../../views/spinner/Spinner";

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = Component => props =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
