import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Card, CardContent, CardActions, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageContainer from "../../components/container/PageContainer";
import Logo from "../../assets/images/logos/logo.png";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../services/auth";
import { CachePortals, SelectPortal } from "../../redux/actions";
import Breadcrumb from "../../layouts/full-layout/breadcrumb/Breadcrumb";
import LoadingScreen from "../../components/loading";

const PortalProvider = ({ children, portal }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [allPortals, setPortals] = useState();
  const [selectPortal, setSelectPortal] = useState();

  useEffect(() => {
    if (portal) {
      setPortals([portal]);
      return;
    }

    if (!auth.user) {
      navigate("/auth/login");
      return;
    }

    const fetchPortal = async () => {
      const { data: portals, error } = await supabase
        .from("portals")
        .select()
        .contains("userIds", [auth.user.id]);

      if (error) {
        navigate("/auth/login");
        return;
      }

      if (portals.length === 0) {
        const portal = await createPortal();
        dispatch(SelectPortal(portal));
        dispatch(CachePortals([portal]));
        navigate("/");
      } else {
        const selected = localStorage.getItem("portal");

        if (portals.length > 1 && !selected) {
          setSelectPortal(portals);
        } else {
          dispatch(SelectPortal(portals[selected ? selected : 0]));
          dispatch(CachePortals(portals));
          setPortals(portals);
        }
      }
    };

    const createPortal = async () => {
      const { data, error } = await supabase
        .from("portals")
        .insert([{ name: "My Games Portal", userIds: [auth.user.id] }]);
      if (error) {
        navigate("/auth/login");
        return;
      }
      return data;
    };

    fetchPortal();
  }, [portal]);

  if (selectPortal) {
    return (
      <PageContainer title="Jogo | Portal" description="Portals">
        <Breadcrumb title="">
          <Typography gutterBottom variant="h3">
            Select a Portal to start
          </Typography>
        </Breadcrumb>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item lg={3}>
            <Box sx={{ p: 5 }}>
              <img src={Logo} alt="jogo" style={{ height: 200 }} />
            </Box>
          </Grid>

          {selectPortal.map((p) => (
            <Grid key={p.id} item xs={12} lg={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h3">
                    {p.name}
                    {p.id}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => {
                      dispatch(SelectPortal(p));
                      dispatch(CachePortals(selectPortal));
                      setPortals(selectPortal);
                      localStorage.setItem("portal", "0");
                    }}
                  >
                    Select
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </PageContainer>
    );
  }

  if (allPortals) {
    return <>{children}</>;
  }

  return <LoadingScreen message="Loading Portals..." view="Portals" />;
};
export default PortalProvider;
