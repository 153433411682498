import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import ThemeSettings from "./layouts/full-layout/customizer/ThemeSettings";
import Router from "./routes/Router";
import "react-perfect-scrollbar/dist/css/styles.css";
import { AuthProvider } from "./services/auth";

const App = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  if (params && params.k === "z25sRve-2") {
    window.location.href =
      "https://io.jogo.studio/render/76259f09-1f73-4fcd-8ca5-1668140c1ccb?pk=c44c99f0-c10d-4869-a63e-e9a2640b3679";

    return <></>;
  }

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
