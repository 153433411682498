const INITIAL_STATE = {
  selectedQuiz: null,
};

function GameReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SELECT_QUIZ":
      return { ...state, selectedQuiz: action.payload };

    default:
      return state;
  }
}

export default GameReducer;
