import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full-layout/loadable/Loadable";
import ProtectedRoute from "../views/authentication/ProtectedRoute";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import("../layouts/full-layout/FullLayout"))
);
const FullNoSide = Loadable(
  lazy(() => import("../layouts/full-layout/FullNoSide"))
);
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank-layout/BlankLayout"))
);

const RenderLayout = Loadable(
  lazy(() => import("../layouts/render-layout/RenderLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);

/* ****Pages***** */
const Render = Loadable(lazy(() => import("../views/Render")));
const RenderPremium = Loadable(lazy(() => import("../views/Render/premium")));
const Dashboard = Loadable(lazy(() => import("../views/dashboard/Dashboard")));
const Games = Loadable(lazy(() => import("../views/Games")));
const Playlists = Loadable(lazy(() => import("../views/Playlists")));
const Frames = Loadable(lazy(() => import("../views/Frames")));
const Themes = Loadable(lazy(() => import("../views/Themes")));
const Analytics = Loadable(lazy(() => import("../views/Analytics")));
const Account = Loadable(lazy(() => import("../views/Account")));
const Hub = Loadable(lazy(() => import("../views/Hub")));
const QuizManager = Loadable(lazy(() => import("../views/QuizManager")));
const QuizEditor = Loadable(lazy(() => import("../views/QuizEditor")));
const Player = Loadable(lazy(() => import("../views/Player")));
const GettingStarted = Loadable(lazy(() => import("../views/GettingStarted")));
const CrosswordManager = Loadable(
  lazy(() => import("../views/CrosswordManager"))
);

/* ****Routes***** */

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        exact: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        )
      },
      {
        path: "/games",
        exact: true,
        element: (
          <ProtectedRoute>
            <Games />
          </ProtectedRoute>
        )
      },
      {
        path: "/playlists",
        exact: true,
        element: (
          <ProtectedRoute>
            <Playlists />
          </ProtectedRoute>
        )
      },
      {
        path: "/themes",
        exact: true,
        element: (
          <ProtectedRoute>
            <Themes />
          </ProtectedRoute>
        )
      },
      {
        path: "/analytics",
        exact: true,
        element: (
          <ProtectedRoute>
            <Analytics />
          </ProtectedRoute>
        )
      },
      {
        path: "/account",
        exact: true,
        element: (
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        )
      },
      {
        path: "/hub",
        exact: true,
        element: (
          <ProtectedRoute>
            <Hub />
          </ProtectedRoute>
        )
      },
      {
        path: "/quiz-manager",
        exact: true,
        element: (
          <ProtectedRoute>
            <QuizManager />
          </ProtectedRoute>
        )
      },
      {
        path: "/quiz-editor/:quizId",
        exact: true,
        element: (
          <ProtectedRoute>
            <QuizEditor />
          </ProtectedRoute>
        )
      },
      {
        path: "/quiz-editor",
        exact: true,
        element: (
          <ProtectedRoute>
            <QuizEditor />
          </ProtectedRoute>
        )
      },
      {
        path: "/player",
        exact: true,
        element: (
          <ProtectedRoute>
            <Player />
          </ProtectedRoute>
        )
      },
      {
        path: "/getting-started",
        exact: true,
        element: (
          <ProtectedRoute>
            <GettingStarted />
          </ProtectedRoute>
        )
      },
      {
        path: "/crossword-manager",
        exact: true,
        element: (
          <ProtectedRoute>
            <CrosswordManager />
          </ProtectedRoute>
        )
      },

      { path: "*", element: <Navigate to="/auth/404" /> }
    ]
  },
  {
    path: "/",
    element: <FullNoSide />,
    //element: <FullLayout />,
    children: [
      {
        path: "/frames",
        exact: true,
        element: (
          <ProtectedRoute>
            <Frames />
          </ProtectedRoute>
        )
      },

      { path: "*", element: <Navigate to="/auth/404" /> }
    ]
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> }
    ]
  },
  {
    path: "/",
    element: <RenderLayout />,
    children: [{ path: "render", element: <Render /> }]
  },
  {
    path: "/",
    element: <RenderLayout />,
    children: [{ path: "premium", element: <RenderPremium /> }]
  }
];

export default Router;
